import React, {Component} from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({text}) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 35.16109898984415,
      lng: 33.360699728836025,
    },
    zoom: 50,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{height: "100vh", width: "100%"}}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyCNHV4hpfmEXUTwrc8IHE_Vy8WJQ_XR678",
          }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={35.16109898984415}
            lng={33.3606997288360254}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
