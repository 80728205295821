import React, {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "./App.css";
import {makeStyles} from "@material-ui/core/styles";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "../src/component/Home";

// import {LocomotiveScrollProvider} from "react-locomotive-scroll";
// import locomotiveScroll from "locomotive-scroll";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function App() {
  const classes = useStyles();
  return (
    <>
      <main>
        {/* <BrowserRouter basename='/cubex'> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </main>
    </>
  );
}

export default App;
