import React from "react";
import Map from "./Map";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
function Home() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="#">
            <img src={require("../image/logo.png")} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item ">
                <a className="nav-link" href="#">
                  Home
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <div className="main">
        <div className="main overfleee">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="main-content">
                  {/* <img src={require("../image/logo.png")} /> */}
                  <h1>A GLOBAL INVESTMENT COMPANY</h1>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card cardneww">
                  <Slider {...settings}>
                    <div>
                      <div className="img-banner">
                        <img src={require("../image/banner2.jpg")} alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="img-banner">
                        <img src={require("../image/banner.jpg")} alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="img-banner">
                        <img src={require("../image/banner3.jpg")} alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="img-banner">
                        <img src={require("../image/banner4.jpg")} alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="img-banner">
                        <img src={require("../image/banner5.jpg")} alt="" />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section_clip pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="abut-content">
                  <h4>About</h4>
                  <div class="elementor-widget-container">
                    <p>
                      Cubex Investments Limited is a European Union based
                      holding company whose primary business activity is to own
                      and manage the financial, corporate and taxation affairs
                      of a portfolio of incorporated businesses in compliance
                      with OECD standards.
                    </p>
                    <p>
                      <span>
                        Cubex Investments Limited provides a financial and
                        business resource to allow its assets enjoyment of more
                        than 48 double tax treaties (DTT’s) under a favourable
                        tax regime and transparent legal system.
                      </span>
                    </p>
                    <p>
                      Cubex Investments Limited is located in a jurisdiction
                      that allows its worldwide operations and multinational
                      interests to benefit from a corporate income tax (CIT)
                      rate of 12.5% along with other taxation and operational
                      advantages.
                    </p>
                    <p>
                      Cubex Investments Limited is set-up to function as a
                      corporate hub for interests in Europe, Asia, the Far East
                      and the Americas.
                    </p>
                    <p>
                      Cubex Investment Limited does not provide investment
                      advice to third parties.
                    </p>
                    <p>
                      Cubex Investment Limited does not provide underwriting
                      facilities or engage in any other similar activities for
                      or of financial instruments.
                    </p>
                    <p>
                      Cubex Investments Limited does not operate a trading
                      platform.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <img src={require("../image/logo.png")} alt="" />
                </div>
                <div className="col-lg-6 col-md-6">
                  <ul>
                    <li>
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                      Chytron 3, Office 301, 1075 Nicosia, Cyprus
                    </li>
                    <li>
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                      Email: info@cubexinvestments.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <p>Copyright © 2022 | Owned by Cubex Investments Limited</p>
          </footer>
        </section>
      </div>
    </>
  );
}

export default Home;
